/* rgb(9,54,51) */
/* rgb(186,145,97) */
/* rgb(232,232,232) */
* {
  padding: 0;
  margin: 0;

  /* outline: 1px solid red; */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* GENERAL */
.rtl-direction {
  direction: rtl;
}

h2 {
  font-size: min(80px, 4.17vw);

  font-weight: 700;
}
.text-green {
  color: rgb(9, 54, 51);
}
.text-amber {
  color: rgb(186, 145, 97);
}

.link-button-amber {
  display: block;
  width: max-content;
  font-size: min(30px, 1.4vw);
  font-weight: 700;

  padding: 2.5% 4%;
  border: none;
  color: white;
  background-color: rgb(186, 145, 97);
  text-decoration: none;
}

.link-button-green {
  display: block;
  width: max-content;
  font-size: min(30px, 1.4vw);
  font-weight: 700;

  padding: 2.5% 4%;
  border: none;
  color: white;
  background-color: rgb(9, 54, 51);
  text-decoration: none;
}

.link-button-amber:hover {
  background-color: rgb(155, 114, 66);
}
.link-button-green:hover {
  background-color: rgb(14, 71, 68);
}

.responsive-text {
  font-size: min(30px, 1.5vw);
}
.responsive-text-small {
  font-size: min(27px, 1.4vw);
}

.App {
  max-width: 1920px;
  margin: auto;
}

.site-header {
  /* width: 100%; */
  /* max-width: inherit; */
  /* background-color: red; */

  display: flex;
  justify-content: flex-end;
  margin-right: 100px;
  align-items: center;
  height: 100%;
}
.site-header-links-container {
  /* outline: 1px solid orange; */
  display: flex;
  height: inherit;
  gap: 100px;

  align-items: center;
}
.site-header-links-wrapper {
  /* outline: 1px solid red; */

  display: flex;

  gap: min(50px);
}

.site-header-link {
  text-decoration: none;
  color: white;
  white-space: nowrap;
  font-weight: 400;

  font-size: min(22px);
}

.site-header-link:visited .site-header-link:link {
  text-decoration: none;
  color: inherit;
}
a:visited a:link {
  text-decoration: none;
  color: inherit;
}

.site-header-language-buton-container,
.site-header-language-buton-container__small {
  position: relative;
}
.site-header-language-buton-container > .tool-tip {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.site-header-language-buton-container__small > .tool-tip {
  margin-top: 0.5vw;
}

.site-header-language-buton-container > .tool-tip > * {
  height: 32px;
  width: 66px;
  margin-top: 5%;

  background: none;
  border: none;

  font-size: 22px;

  color: rgb(255, 255, 255);
  outline: 2px solid white;
  outline-offset: -2px;

  cursor: pointer;
}

.site-header-language-button {
  height: 32px;
  width: 66px;
  border: none;
  background: none;

  outline: 2px solid rgb(186, 145, 97);
  color: rgb(186, 145, 97);
  outline-offset: -2px;

  font-size: 22px;

  cursor: pointer;
  z-index: 20;
}
.site-header-language-buton-container__small > .tool-tip > * {
  height: 10%;
  width: 25%;
  max-width: 50px;

  background: none;
  border: none;

  font-size: min(20px, 4vw);

  color: rgb(9, 54, 51);
  outline: 2px solid rgb(9, 54, 51);
  outline-offset: -2px;

  cursor: pointer;
}

.site-header-language-button__small {
  height: 10%;
  width: 25%;
  border: none;
  background: none;

  outline: 2px solid rgb(186, 145, 97);
  color: rgb(186, 145, 97);
  outline-offset: -2px;

  font-size: min(20px, 4vw);

  cursor: pointer;
  z-index: 20;
}

/* HOME */
/* HOME */
/* HOME */

.home-container {
  background-color: #e8e8e8;
}

.home-container > * {
  scroll-snap-align: end;
  scroll-snap-stop: always;
}

.hero-section {
  max-height: 1080px;
  height: 100dvh;
  display: grid;
  grid-template-rows: 118px 1fr;
}

.hero-section-main {
  width: 100%;

  overflow: hidden;
  display: flex;
  position: relative;
}
.hero-section-main__image {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.hero-section-main__welcome {
  position: absolute;

  width: 100%;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  align-items: center;
}

.welcome-cotainer {
  background-color: rgb(186, 145, 97);
  padding: min(2.75vw, 52px) min(6.52vw, 125px);
  position: absolute;
  right: min(117px, 6.1vw);
}

.welcome-cotainer-text {
  color: white;

  margin-bottom: 7.5%;
}

.top-section {
  background: white;

  max-height: 1080px;
  height: 100dvh;
  /* aspect-ratio: 16/9; */
  display: grid;
  grid-template-rows: 1fr 80%;
}

.top-section-head {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.top-section-img-container {
  overflow: hidden;
}
.top-section-img {
  display: block;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.top-section-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-section-info-container {
  width: 80%;
}
.top-section-info__name {
}
.top-section-info__text {
  margin: 5% 0;

  color: rgb(9, 54, 51);
  font-weight: 500;
}

.ribbon-section {
  background-color: rgb(232, 232, 232);

  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.ribbon-image-conatainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7.5%;
  margin: 1% 0rem;
  font-weight: 600;
  color: #acacac;
}
.ribbon-image-conatainer > img {
  /* height: 25%; */
  /* height: 2dvh; */
  width: 5dvw;

  max-width: 75px;
  /* max-height: 75px; */
}
.ribbon-image-conatainer > p {
  font-size: min(18px, 1.2vw);
}

.second-section {
  height: 100dvh;
  max-height: 1080px;
  display: grid;
  grid-template-columns: 4fr 5fr;
  background-color: white;
}

.second-section-image-container {
  display: flex;
  height: 100%;
  max-height: inherit;
}

.second-section-image-one {
  width: 47%;
  height: inherit;
  max-height: inherit;
  object-fit: cover;
}
.second-section-image-two {
  width: 70%;
  height: 70%;

  position: relative;
  left: -12%;
  margin: auto;
  object-fit: cover;
}

.second-section-text-container {
  height: 100%;
  max-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.second-section-header {
  color: rgb(186, 145, 97);
}
.second-section-text {
  font-weight: 500;
  margin: 4% 0;
  color: rgb(9, 54, 51);
  width: 65%;

  text-align: justify;
  word-break: keep-all;
}
.second-section-button {
  font-size: 1.4rem;
  font-weight: 600;

  padding: 1rem 5rem;
  border: none;
  color: white;
  background-color: rgb(9, 54, 51);
}

.third-section {
  height: 100dvh;
  max-height: 1080px;
  background-color: rgb(9, 54, 51);
  display: grid;
  grid-template-rows: 80% 1fr;
}
.third-section-head {
  max-height: 80dvh;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.third-section-image-container {
  height: 100%;

  overflow: hidden;

  max-height: inherit;
}
.third-section-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
}

.third-section-text-container {
  height: 100%;
  width: 100%;
  display: flex;
}

.third-section-text-wrapper {
  margin: auto;
  margin-left: min(150px, 7.8vw);
  margin-right: min(150px, 7.8vw);
}
.third-section-text {
  font-weight: 400;
  margin: 4% 0;
  color: white;
}

.third-section-text-header {
  color: white;
}

.forth-section {
  padding: 5%;
  background-color: rgb(9, 54, 51);
}

.forth-section-item {
  display: grid;
  grid-template-columns: 1fr 1.1fr;
}
.forth-section-image-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forth-section-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.forth-section-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

.forth-section-text-container__left_overflow {
  position: absolute;
  left: -21%;
  right: 0;
  padding: min(75px, 3.9vw) 10%;

  /* padding-left: min(150px, 7.8vw); */

  /* padding-right: min(217px, 11.3vw); */

  background-color: rgb(186, 145, 97);
}
.forth-section-text-container__right_overflow {
  position: absolute;
  right: -21%;

  left: 0;

  padding: min(75px, 3.9vw) 10%;
  /* padding-inline: min(150px, 7.8vw) min(50px, 2.5vw); */

  background-color: rgb(186, 145, 97);
}

.forth-section-header {
  color: white;
}

.forth-section-text {
  font-weight: 500;
  margin: 3% 0;
  color: rgb(9, 54, 51);
}

.forth-section-list {
  list-style: " - ";
}
.forth-section-list-item {
  list-style-position: inside;
  font-weight: 500;
  color: rgb(9, 54, 51);
}

/* FOOTER */

.footer-outer-wrapper {
  padding: 0 min(196px, 10.2vw);
}

.footer-wrapper {
  background-color: #e8e8e8;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: min(65px, 3.4vw);
  padding-bottom: min(65px, 3.4vw);
}
.footer__left,
.footer__mid,
.footer__right {
  display: flex;
  flex-direction: column;
  gap: min(1vh, 20px);
}
.footer__left {
  color: rgb(9, 54, 51);
  font-weight: 600;
}
.footer__mid {
  color: rgb(186, 145, 97);
  font-weight: 700;
  text-align: center;
}
.footer__right {
  align-items: flex-end;
}
.footer__right > * > img {
  width: 3vw;
  max-width: 50px;
}

/* ROOMS */

.rooms-section {
  background-color: rgb(232, 232, 232);
}

.default-section-header {
  background: rgb(9, 54, 51);
  max-height: 118px;
  height: 12.7dvh;
  /* height: 6.1dvw; */
}
.default-section-header__main {
  background: rgb(9, 54, 51);
  /* padding: 2dvh 0; */
  /* height: 2dvh; */
  /* max-height: 100%; */
  /* min-height: max-content; */
}

/* ROOMS */
.rooms-main-wrapper {
  margin: 0 min(192px, 9vw);
}
.rooms-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 4.4vw;
}

.room-cotainer {
  display: flex;

  max-width: 1537px;
  /* width: 80vw; */

  height: 23.4vw;
  max-height: 449px;
  padding: min(10px, 0.5vw);
  gap: min(10px, 0.5vw);

  background-color: white;
}

.room-info-container {
}

.room-info-container-image-container > img {
  width: 40.4vw;
  max-width: 775px;

  height: 100%;
  object-fit: cover;
}

.room-info-container-text-container {
  padding: min(27px, 1.4vw) min(60px, 3.1vw);
  display: grid;
  width: 100%;
}

.room-info-container-text-name {
  font-size: min(30px, 1.55vw);
  font-weight: 500;
  color: rgb(186, 145, 97);
}
.room-info-container-text-desc {
  margin: min(19px, 1vw) 0;

  font-size: min(26px, 1.36vw);
}
.room-info-container-img {
  height: 1.77vw;
  max-height: 34px;

  /* margin-top: min(59px, 3.1vw); */
  /* margin-bogttom: min(99px, 5vw); */
}
.room-info-container-text-button {
  width: max-content;
  height: max-content;
  padding: min(0.6vw, 11px) min(1vw, 20px);

  font-weight: 600;
  font-size: min(26px, 1.36vw);
  border: none;
  background-color: rgb(9, 54, 51);
  color: white;
  align-self: flex-end;
}

.room-thumbnail-images-wrapper {
  display: flex;
  flex-direction: column;

  gap: min(10px, 0.5vw);
  justify-content: space-evenly;
}

.room-thumbnail-images-container {
  overflow: hidden;

  max-width: 100px;
  width: 5.25vw;
  height: 5.25vw;
}
.room-thumbnail-images-container > img {
  max-width: 100px;
  width: 5.25vw;

  height: 100%;

  object-fit: cover;
}

/* GALLERY */

.gallery-section {
  background-color: #e8e8e8;
}

.gallary-dynamic-grid-container {
  display: grid;
  padding: min(1vw, 1rem);
  gap: min(25px, 1.25vw);
}

.gallary-dynamic-container {
  /* height: 500px; */
}
.gallary-dynamic-grid__one,
.gallary-dynamic-grid__two,
.gallary-dynamic-grid__three {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  aspect-ratio: 16/6;

  overflow: hidden;
  gap: min(25px, 1.25vw);
}

.gallary-dynamic-grid__one > *,
.gallary-dynamic-grid__two > *,
.gallary-dynamic-grid__three > * {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: min(25px, 1.25vw);
  overflow: hidden;
  background-color: white;
  object-fit: cover;
}

.gallary-dynamic-grid__one > * > img,
.gallary-dynamic-grid__two > * > img,
.gallary-dynamic-grid__three > * > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: white;
  overflow: hidden;
}

.gallary-dynamic-grid__one {
  grid-template-areas:
    "a a b d"
    "a a c d";
}
.gallary-dynamic-grid__two {
  grid-template-areas:
    "a b d d"
    "c c d d";
}

.gallary-dynamic-grid__three {
  grid-template-areas:
    "a c c c"
    "b c c c";
}

.gallary-dynamic-grid__three > *:nth-child(1),
.gallary-dynamic-grid__two > *:nth-child(1),
.gallary-dynamic-grid__one > *:nth-child(1) {
  grid-area: a;
}
.gallary-dynamic-grid__three > *:nth-child(2),
.gallary-dynamic-grid__two > *:nth-child(2),
.gallary-dynamic-grid__one > *:nth-child(2) {
  grid-area: b;
}
.gallary-dynamic-grid__three > *:nth-child(3),
.gallary-dynamic-grid__two > *:nth-child(3),
.gallary-dynamic-grid__one > *:nth-child(3) {
  grid-area: c;
}
.gallary-dynamic-grid__two > *:nth-child(4),
.gallary-dynamic-grid__one > *:nth-child(4) {
  grid-area: d;
}

/* BOOKING */

.booking-section {
  background-color: #e8e8e8;
}
.booking-main {
  margin: 0 min(196px, 10.2vw);
}

.booking-main-header-wrapper {
  margin-top: min(90px, 4.7vw);
  margin-bottom: min(40px, 2vw);
}

.email-booking-form {
  background-color: white;

  height: min-content;
  padding: min(50px, 3.45vw);
}

.email-booking__upper {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;

  gap: min(30px, 2vw) min(50px, 3.45vw);
}

.email-booking__upper-input {
  height: 2.93vw;
  max-height: 60px;
  font-size: min(25px, 1.3vw);
  background-color: #e8e8e8;
  border: none;
  padding-inline: min(33px, 1.9vw) 0;
  border-radius: 0;
}

.upper-data-container {
  display: flex;
  align-items: center;
  gap: 0.2vw;
  color: #757575;
}
.upper-data-container > p {
}

.booking__upper-input__date-container {
  font-size: min(20px, 1.045vw);
  display: flex;
  align-items: center;
  width: 100%;
  height: 85%;
  gap: min(0.5vw, 10px);

  padding-inline: min(0.5vw, 10px) 0;
}
.booking__upper-input__date-container:last-of-type {
  margin-inline-end: min(0.2vw, 4px);
}

.booking__upper-input__date-container > p {
  white-space: nowrap;
  color: #353535;
}
.booking__upper-input__date-container > input {
  font: inherit;
  border: none;
  background: none;
  background: rgb(209, 209, 209);
  height: 100%;
  width: 100%;
  padding-inline: min(0.5vw, 10px);
}

.email-booking__lower {
  margin-top: min(30px, 2vw);
  display: grid;
  grid-template-columns: 1fr 14.5%;
  aspect-ratio: 14.8/1;
  gap: 0 min(45px, 2.6vw);
}

.email-booking__lower-textarea {
  font: inherit;
  font-size: min(25px, 1.3vw);
  background-color: #e8e8e8;
  border: none;
  padding: 0 min(33px, 1.9vw);
  padding-top: min(15px, 0.8vw);

  resize: none;
}

.email-booking__lower-button {
  color: white;
  background: #093633;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: min(25.6px, 1.35vw);
  text-align: left;

  font-size: min(22px, 1.15vw);
  font-weight: 600;
  border-radius: min(15px, 0.8vw);
  cursor: pointer;
  height: 5vw;
  max-height: 96px;
}

.email-booking__lower-button:active {
  background: #174946;
}

.email-booking__lower-button > img {
  width: 1.55vw;
  max-width: 29.25px;
}

.booking-notification-section {
  margin: min(2.85vw, 55px) 0;
}
.booking-notification-section__imp-wrapper {
  background-color: #ba9261;
  color: white;
  font-size: min(40px, 2.08vw);
  text-align: center;
  margin-bottom: min(29px, 1.55vw);
  padding: 0.25vw 0;
  font-weight: 700;
}

.booking-notification-section__imp-wrapper > p {
  padding: min(5px, 0.25vw) 0;
}
.booking-notification-section__list {
}
.booking-notification-section__list-item {
  text-align: center;
  font-size: min(25px, 1.3vw);
  font-weight: 600;
  text-transform: uppercase;
  color: #ba9261;
}

.book-section-header {
}
.book-section-header__img {
  width: 100%;
  height: 100dvh;
  max-height: 1080px;
  object-fit: cover;
}

.book-main-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.book-main {
  background: rgb(186, 145, 97);
  top: -20dvh;
  width: 80%;
  position: absolute;
}

.book-whatapp-container {
  display: flex;
  flex-direction: column;
}

.book-top-data {
  background-color: antiquewhite;
  height: 3rem;
  width: 100%;
}

.book-room-tool-tip {
  top: 20%;
  position: relative;
  height: 100%;
  z-index: 20;
}

.select-rooms-container {
  width: 12rem;
}

.select-rooms {
  display: flex;
  justify-content: space-between;
}

.select-rooms__logic {
  display: flex;
  gap: 5px;
  align-items: center;
}
.select-rooms__button {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 600;
}

.book-inputs-wrapper {
  margin: auto;
  width: 50%;
  padding: 1rem 0;
}

.book-inputs-container {
  margin-top: 1rem;
}

.book-inputs-name {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}
.book-inputs-input {
  width: 100%;
  font-size: 1.4rem;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.555);
  background: none;
  color: white;
}

.book-inputs-input:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.555);
}

.book-inputs-input__note {
  width: 100%;
  font-size: 1.4rem;
  resize: none;
}

.book-input__submit {
  width: 20%;
  padding: 1%;
  max-width: 400px;
  border: none;
  background: rgb(9, 54, 51);
  color: white;
}

/* MODAL */
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.452);
}
.modal-image-container {
  max-width: 95%;
  max-height: 95%;
  margin: 10%;
  overflow: hidden;
}
.modal-image {
  max-height: 100%;
  max-width: 100%;
  height: inherit;
  overflow: hidden;
  object-fit: cover;
}

/* ABOUT US */

.aboutus-section {
}
.aboutus-head {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: rgb(9, 54, 51);
}
.aboutus-head-description-container {
  height: 100%;
  width: 100%;
  display: flex;

  align-items: center;
  position: relative;
  left: max(-150px, -7.8vw);
}
.aboutus-head-description {
  position: absolute;

  padding: min(36px, 2.35vw) min(2.9vw, 45px);
  color: white;
  background: #ba9261;
}
.aboutus-head-description > p {
  margin: 4% 0rem;
  font-size: min(30px, 1.5vw);
  font-weight: 600;
  color: #093633;
}

.aboutus-head-imag-container {
}
.aboutus-head-imag-container > img {
  width: 100%;
  height: 100%;
}

.aboutus-list-wrapper {
  display: grid;
  /* flex-wrap: wrap; */
  grid-template-columns: 1fr 1fr;
  /* justify-content: space-between; */

  gap: 2rem;
  padding: 5rem;
}
.aboutus-list-container {
  /* background-color: rgb(186, 145, 97); */
  background-color: rgb(241, 241, 241);
  /* outline: 5px solid rgb(186, 145, 97); */
  /* outline-offset: -5px; */
}
.aboutus-list-name-container {
  background-color: rgb(9, 54, 51);
  padding: 1rem;
}
.aboutus-list-name {
  color: white;
  font-size: 35px;
  font-weight: 500;
}
.aboutus-list-items-container {
  padding: 0.5rem 1rem;
}
.aboutus-list-item {
  color: rgb(9, 54, 51);
  font-size: 30px;

  margin: 5px 0px;
}

.aboutsus-right-container,
.aboutsus-left-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #e8e8e8;
  width: 100%;
  overflow: hidden;
}
.aboutsus-right-container {
  /* height: 37vh; */
  /* max-height: 402px; */
}
.aboutsus-left-container {
  /* height: 31vh; */
  /* max-height: 338px; */
}

.aboutsus-right-text-container,
.aboutsus-text-container {
  padding-inline: min(60px, 3vw) 0;
  padding-top: min(60px, 3vw);

  overflow: hidden;
}
.aboutsus-text-header {
  font-size: min(30px, 1.5555vw);
  font-weight: 700;
  color: rgb(155, 114, 66);
}

.aboutsus-left-image-container,
.aboutsus-right-image-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.aboutsus-image {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}

.aboutsus-list-container {
  list-style-type: disc;
  padding: min(20px, 0.999vw);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 8vw;
  max-height: 200px;

  overflow: hidden;
  gap: min(26px, 1vw);
}
.aboutsus-list-item {
  list-style-position: inside;
  font-size: min(22px, 1.145vw);
}
/* site-header */

.site-header-tool-tip-button {
  display: none;
  position: relative;
  height: 100%;
  z-index: 20;
}

.tool-tip-button {
  border: none;
  background: none;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.tool-tip-button > img {
  /* height: min(15px, 4dvh); */
  width: 4vw;
  max-width: 30px;
  /* height: 10px; */
}

.site-header-tool-tip-button > .tool-tip {
  background-color: rgb(255, 255, 255);
  /* border: 1px solid rgb(9, 54, 51); */
  position: absolute;
  z-index: 20;
  right: -10%;
  top: 75%;
  width: 45dvw;
  max-width: 200px;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.514);
  display: flex;
  flex-direction: column;
}
.site-header-tool-tip-button > .tool-tip > * {
  background: white;
  border: none;
  color: rgb(9, 54, 51);
  font-size: min(30px, 1.5vw);
  font-weight: 600;

  display: block;
  padding: 7.5% 5%;
  text-decoration: none;
  font-size: min(20px, 4vw);
}
.site-header-tool-tip-button > .tool-tip > a:hover {
  background-color: rgb(221, 221, 221);
}

@media only screen and (max-width: 1200px) {
  /* TABLETS */

  .default-section-header {
    height: 60px;
  }

  .site-header-tool-tip-button {
    display: block;
  }
  .site-header-links-wrapper {
    display: none;
  }
  .site-header-language-buton-container {
    display: none;
  }

  .home-container {
    display: flex;
    flex-direction: column;
  }

  .hero-section {
    height: 100%;
    grid-template-rows: 60px 1fr;
  }

  .site-header {
    margin-right: 5%;
  }
  .top-section {
    height: 100%;
  }

  .second-section {
    height: 100%;
    overflow: hidden;
  }

  .third-section {
    height: 100%;
    overflow: hidden;
  }
  .forth-section {
    height: 100%;
    overflow: hidden;
  }

  .ribbon-image-conatainer {
    padding: 1dvh 0;
    gap: 2px;
  }

  .aboutus-list-wrapper {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
}

@media only screen and (max-width: 425px) {
  /* MOBILE PHONES */

  h2 {
    font-size: 16px;
  }
  .responsive-text {
    font-size: 8px;
  }
  .responsive-text-small {
    font-size: 8px;
  }
  .link-button-green {
    font-size: 8px;
    font-weight: 700;
    padding: 5px;
  }

  .hero-section-main__welcome {
    /* left: auto; */
    /* right: auto; */
    justify-content: flex-end;
  }
  .welcome-cotainer {
    /* right: 0; */
    /* left: 0; */
    /* width: 75%; */
  }
  .top-section {
    display: block;
    height: auto;
  }
  .top-section-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }

  .top-section-head {
    grid-template-columns: 1fr;
    grid-template-rows: 1.5fr 1fr;
    height: 298px;
  }

  .top-section-info__text {
    margin: 10px 0;
  }

  .forth-section {
    padding: 0.5rem;
  }

  .gallery-section-image {
    width: 250px;
    height: 150px;
  }
}
